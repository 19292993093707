/* App.css */
.App {
    text-align: center;
    padding: 20px;
}

/* Navbar.js */
.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px;
}

/* Form.js */
.form-container {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px auto;
    max-width: 400px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    margin-bottom: 10px;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.input-group input,
.input-group select {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttons {
    text-align: center;
    margin-top: 15px;
}

.buttons button {
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.buttons button:nth-child(1) {
    background-color: #4caf50;
    color: #fff;
}

.buttons button:nth-child(2) {
    background-color: #f44336;
    color: #fff;
}

/* WorkerDashboard.js and RequesterDashboard.js */
.dashboard-container {
    border: 1px solid #ccc;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dashboard-container h2 {
    margin-bottom: 10px;
}

.side-panel {
    background-color: #333;
    color: #fff;
    width: 250px;
    height: 100%;
    float: left;
}

.tab-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tab-list li {
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab-list li:hover {
    background-color: #555;
}

.content {
    margin-left: 260px;
    /* Adjust based on the side panel width */
    padding: 20px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Navigation Button Styles */
.nav-links {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    /* Center horizontally */
}

.nav-links button {
    background: White;
    color: Black;
    padding: 15px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: color 0.3s;
}

.nav-links a {
    background: White;
    color: Black;
    padding: 15px;
    border-radius: 5px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
    transition: color 0.3s;
}

.nav-links a:hover {
    color: rgb(80, 0, 255);
}

.nav-links button:hover {
    color: #555;
}

/* Add these styles to your styles.css or appropriate CSS file */

/* Style for the RequestTaskForm container */
.RequestTaskForm {
    margin-top: 20px;
}

/* Style for form elements */
.RequestTaskForm form {
    display: flex;
    flex-direction: column;
}

.RequestTaskForm input,
.RequestTaskForm textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 14px;
}

.RequestTaskForm textarea {
    resize: vertical;
}

.RequestTaskForm button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.RequestTaskForm button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}


/* Style for the tasks list container */
.tasks-list {
    margin-top: 20px;
    list-style-type: decimal;
    /* Show numbers instead of bullets */
    padding-left: 20px;
    /* Adjust the indentation */
}

/* Style for each task item */
.tasks-list li {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tasks-list h3 {
    margin: 0;
    font-size: 1.2rem;
}

.tasks-list p {
    margin: 10px 0;
}

.tasks-list button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.tasks-list button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}